.card {
  .card-body {
    a {
      h5.card-title {
        color: $primary;
      }
    }
  }
}
// read more text
a.read-more {
  color: $primary;

  .icon {
    fill: $primary;
  }
}
