.btn-primary,
.btn-secondary,
.btn-tertiary {
  border: 2px solid $link-hover-color;

  &:hover,
  &:active {
    border: 2px solid $link-hover-color;
    background-color: $link-hover-color;
  }
}
