.it-footer-main {
  background-color: $footer-bg-color;
  .it-brand-wrapper {
    a {
      .icon {
        width: auto;
      }
    }
  }
}

h4 {
  padding-bottom: 0.4em;
  border-bottom: 1px solid rgba($white, 0.5);
  margin-bottom: 0.4em;

  &.no-border {
    padding: 0;
    border: none;
    margin-bottom: 1.5rem;
    line-height: 1rem;

    a {
      text-decoration: underline;
    }
  }
}

.it-footer-small-prints {
  background-color: $footer-bg-color;

  .container {
    border-top: 1px solid $white;
  }
}
