//site
$primary-h: 204 !default;
$primary-s: 73 !default;
$primary-b: 67 !default;

$ucman-primary: #2e79ab; //è il primary.

$secondary: #455a64; // #368fc9;
$tertiary: $ucman-primary;
//$tertiary-text: $white;

$light: #eceff1;

$link-hover-color: #107e9e;
//$header-bg-color: $monet-blue;
$header-center-bg-color: $ucman-primary;

//$headings-color: $primary;
$semibold: 600;

$footer-bg-color: #013daf;

$argument-icon-color: $ucman-primary;
$argument-icon-bg: rgba($ucman-primary, 0.08);

// GDRP-BANNER RELATED
// $gdpr-accept-all: #005700 !default;
// $gdpr-toggle-checked: #005700 !default;
// $gdpr-toggle: #b22515 !default;
// $gdpr-toggle-border: #000 !default;
// $gdpr-focus-color: #ff9800 !default;
// $gdpr-link-color: #004285 !default;
// $gdpr-focus-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
//   0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $gdpr-focus-color !default;

// $header-slim-bg-color: $primary-dark;

//$headings-color: $primary;
//$link-color: hsl(210deg, 100%, 40%);
