.navbar {
  // navbar mobile
  .navbar-collapsable {
    /*lista menu*/
    .navbar-nav:not(.navbar-secondary) {
      li {
        a.nav-link {
          font-weight: $semibold;
        }
      }
    }
  }
}

@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .navbar {
    // navbar mobile
    .navbar-collapsable {
      /*lista menu*/
      .navbar-nav:not(.navbar-secondary) {
        li {
          a.nav-link {
            &:hover,
            &:active {
              svg {
                color: $primary-text;
                fill: $primary-text;
              }
            }
          }
        }
      }
    }
  }
}
