.megamenu {
  .link-list-wrapper {
    .link-list {
      &.bordered {
        li {
          a {
            span {
              color: $secondary;
            }
          }
        }
      }
    }
  }
}
