a {
  &:hover {
    color: $link-hover-color;

    .icon {
      color: $link-hover-color;
      fill: $link-hover-color;
    }
  }
}
